.gradient-background {
  background: linear-gradient(
      0deg,
      #1addffb2 0%,
      #1addffb2 0%,
      #3998ffe2 10%,
      #4383ffd2 19%,
      #5757ffe1 29%,
      #6c27fff1 42%,
      #7a0afffb 67%,
      #7f00ff 77%,
      #7f00ff 79%,
      #7f00ff 100%
    ),
    url("../assets/images/background.jpg");
  background-size: cover;
}

.dealer-background {
  background: url("../assets/images/cars-dealer.png");
  filter: grayscale(80%);
  background-size: cover;
}

.image-border-gradient {
  background: transparent
    linear-gradient(
      180deg,
      #1addffb2 0%,
      #1addffb2 0%,
      #3998ffe2 10%,
      #4383ffd2 19%,
      #5757ffe1 29%,
      #6c27fff1 42%,
      #7a0afffb 67%,
      #7f00ff 77%,
      #7f00ff 79%,
      #7f00ff 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;
}
b {
  font-weight: bold !important;
}

.gradient-header {
  background: transparent linear-gradient(167deg, #7f00ff 0%, #01addf 100%) 0%
    0% no-repeat padding-box;
}

a.active {
  color: #7f00ff !important;
}

.white-placeholder::placeholder {
  color: white !important;
}

@media screen and (min-width: 320px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 480px) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .gradient-background.mobile-remove {
    background: #7f00ff;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 860px) {
  html {
    font-size: 15px;
  }
}
@media screen and (min-width: 920px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 1250px) {
  html {
    font-size: 18px;
  }
}
@media screen and (min-width: 1366px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-width: 1440px) {
  html {
    font-size: 22px;
  }
}
@media screen and (min-width: 1600px) {
  html {
    font-size: 23px;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 26px;
  }
}

#root {
  overflow-x: hidden;
}

.fontSize05 {
  font-size: 0.5rem;
  width: 0.7rem;
  line-height: 0.7rem;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

::placeholder {
  color: #384364 !important;
}

:root {
  --mdc-theme-secondary: #7f00ff;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-size: 0.5rem !important;
}

.react-datepicker-popper {
  top: -40px;
  z-index: 4;
}

.react-datepicker__current-month {
  font-size: 0.5rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.1rem !important;
  width: 1.3rem;
  line-height: 1.5rem;
}
